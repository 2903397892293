body {
  font-family: Roboto, sans-serif;
  background: #f5f5f5;
  color: #333;
  font-size: 1em;
  padding: 0em;
  padding-bottom: 1em;
  margin: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}
.main {
  padding: 1em;
  padding-top: 2em;
}
a {
  text-decoration: none;
  font-weight: 400;
  color: #ec922a;
}
a:hover {
  text-decoration: underline;
  color: #ea922a;
}
a:focus {
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0.4em 0em;
}

footer {
  text-align: center;
  padding-top: 1em;
}

h1 {
  text-transform: uppercase;
  font-size: 1.7em;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

h2,
.h2 {
  text-transform: uppercase;
  font-size: 1.25em;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h3,
.h3 {
  font-size: 1.1em;
  font-weight: 300;
  margin: 0;
  padding: 0.2em 0em;
}

h4,
.h4 {
  font-size: 1.1em;
  font-weight: 400;
}

ul.list-unstyled {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

ul {
  margin: 0;
  padding: 0em 0em 0em 2em;
}

li {
  padding: 0;
  margin-bottom: 0.3em;
}
li:last-child {
  margin-bottom: 0;
}

.empty {
  opacity: 0;
}

.time,
.place {
  font-size: 0.9em;
}

.separator::before {
  content: " | ";
}

.item {
  margin-bottom: 1em;
}
.item:last-child {
  margin-bottom: 0;
}

.award {
  font-weight: 400;
}

.job-title {
  font-weight: 600;
}

.wrapper {
  display: flex;
  max-width: 65em;
  background-color: white;
  margin: 0 auto;
  position: relative;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.sidebar-wrapper {
  width: 16.5em;

  .contact {
    .fa {
      margin-right: 0.5em;
      font-size: 1em;
      vertical-align: middle;
    }
    .email {
      .fa {
        font-size: 0.9em;
      }
    }
  }
}

.main-wrapper {
  background: white;
  flex: 0.99;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  .item {
    margin-bottom: 1.5em;
  }
  .item:last-child {
    margin-bottom: 0;
  }
}

.projects {
  .item {
    margin-bottom: 0.5em;
  }
}

#preview {
  position: fixed;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);

  div {
    display: block;
    position: relative;
    margin: auto;
    max-width: 95vh;
    max-height: 95vh;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    div.description {
      margin: 0;
      padding: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
    }
  }
}

section.design {
  div.galery {
    display: flex;
    flex-wrap: wrap;

    div.picture {
      margin: 0.25em;
      padding: 0;
      width: 8em;
      height: 8em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      display: flex;

      img {
        margin: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

section {
  padding: 1.2em 2em;
}

div.heading {
  vertical-align: top;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

div.description {
  margin: 0;
  padding: 0;
}

.caret-open {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-bottom: 0 dotted;
  border-left: 4px solid transparent;
  content: "";
}
.caret-close {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 0 dotted;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #000000;
  border-left: 4px solid transparent;
  content: "";
}

.certificates {
  ul {
    padding: 0.5em 0 0.5em 0;
  }
}

/**
 * Extra small devices (phones, less than 768px)
 */
@media (max-width: 48em) {
  .wrapper {
    display: block;
  }

  .sidebar-wrapper {
    position: static;
    width: inherit;
    border: none;
    display: flex;
    flex-wrap: wrap;
    padding: 1em;

    section {
      flex-direction: column;
      flex: 0.5 15em;
      padding: 1em;
      margin: 0;
    }
  }
  .main-wrapper {
    padding: 0;
    border: none;
  }
}

.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 32px;
  height: 18px;
  display: inline-block;
  position: relative;
  border-radius: 25px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #707070;
  transition: background-color ease 0.3s;
  transform: translate(0, 30%);
}

.toggle:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  width: 14px;
  height: 14px;
  background: #fff;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -11px;
  word-spacing: 19px;
  color: #fff;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:checked {
  background-color: #ec922a;
}

.toggle:checked:before {
  left: 16px;
}

@media print {
  .profile {
    display: none;
  }
  .wrapper {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .sidebar-wrapper {
    width: 15em;
    margin: 0;
    padding: 0;

    section {
      flex-direction: column;
      flex: 15em;
      padding: 0.5em;
      margin: 0;
    }
  }

  .main-wrapper {
    flex: 0.99;
    margin: 0;
    padding: 0;

    section {
      flex-direction: column;
      flex: 15em;
      padding: 0.5em;
      margin: 0;
    }
  }
}
